import React from 'react';

import { getBanner } from 'models/banner';
import { checkPartnerStatus } from 'models/partner';

import ActivityRoute from './Activity';
import AboutRoute from './About';
import PartnerRoute from './Partner';
import ContactRoute from './Contact';
import SearchRoute from './Search';
import BlogsRoute from './Blogs';
import SubscribeSuccessRoute from './SubscribeSuccess';
import LegalMapRoute from './LegalMap';

const routes = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		console.log('on Enter Root');

		await store.dispatch(getBanner());
		await store.dispatch(checkPartnerStatus());

		const children = await next();
		console.log('on Enter Root / end');

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ next }) => {
				console.log('on Enter Home');
				const children = await next();
				console.log('on Enter Home / end');
				return children;
			},
		},
		ActivityRoute,
		AboutRoute,
		PartnerRoute,
		ContactRoute,
		SearchRoute,
		BlogsRoute,
		SubscribeSuccessRoute,
		LegalMapRoute,
	],
};

export default routes;
